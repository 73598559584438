import { Select } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux'
import ExpandMoreSharpIcon from '@material-ui/icons/ExpandMoreSharp'
import * as actionCreators from '../../../../store/actions'

const uuidv4 = require('uuid/v4')

export class ReportOrderList extends React.Component {
  /*-----------------------------------------------------*/
  // Select Orders
  /*-----------------------------------------------------*/

  updateOrder = value => {
    const { order, section, collection, rankingType, updateReportOrder } = this.props

    const updatedOrder = { ...order }

    if (
      !updatedOrder[collection] ||
      !updatedOrder[collection][section] ||
      updatedOrder[collection][section][rankingType] !== value
    ) {
      const currOrder = {}
      currOrder[rankingType] = value

      if (!updatedOrder[collection]) updatedOrder[collection] = {}
      if (!updatedOrder[collection][section]) updatedOrder[collection][section] = {}

      updatedOrder[collection][section] = {
        ...updatedOrder[collection][section],
        ...currOrder,
      }
    }

    updateReportOrder(updatedOrder)
  }

  /*-----------------------------------------------------*/
  // Get Values
  /*-----------------------------------------------------*/

  isValue = value => {
    const { section, order, rankingType, collection } = this.props
    return (
      order[collection] &&
      order[collection][section] &&
      order[collection][section][rankingType] &&
      order[collection][section][rankingType] === value
    )
  }

  getValue = valuesList => {
    if (!valuesList) return ''
    const updateValuesList = [...valuesList]
    const currValue = updateValuesList.pop()

    if (!currValue) return ''

    if (this.isValue(currValue)) return currValue
    return this.getValue(updateValuesList)
  }

  /*-----------------------------------------------------*/
  // Render Global Select
  /*-----------------------------------------------------*/

  render() {
    const { texts, values, allowNullValue } = this.props

    const list = values && Array.isArray(values) ? values : ''
    const menuItemsValues = allowNullValue ? ['', ...list] : [...list]

    return (
      <div className="report-order-list report-select">
        <span className="report-select-label">{texts.report_order}</span>
        <Select
          value={this.getValue(list)}
          onChange={event => this.updateOrder(event.target.value)}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          IconComponent={ExpandMoreSharpIcon}
          native
        >
          {menuItemsValues.map(menuItemsValue => {
            let selectText = ''
            if (menuItemsValue) selectText = texts[`report_${menuItemsValue}`]

            return (
              <option key={uuidv4()} value={menuItemsValue}>
                {selectText}
              </option>
            )
          })}
        </Select>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    reportId: state.report.reportObj.id,
    nodes: state.report.reportObj.nodes,
    scoringTree: state.scoringTree,
    texts: state.texts.values,
    order: state.report.reportOrder,
  }
}

export default connect(mapStateToProps, actionCreators)(ReportOrderList)
