import { Checkbox } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../store/actions'
import reportConf from '../../helper/reportConf'
import reportRankingTypeMap from '../../helper/reportRankingTypeMap'

export class ReportTopLowestSelect extends React.Component {
  /*-----------------------------------------------------*/
  // Check/Uncheck Event
  /*-----------------------------------------------------*/

  toggleTopLowestSelection = event => {
    // stop propagation in order to don't see the node detail
    // or open submenu when check it

    event.stopPropagation()

    const { updateReportTopLowest, itemId, topLowest, section, rankingType, scoringTree, reportId } = this.props

    const updatedTopLowest = { ...topLowest }

    if (updatedTopLowest[section]) {
      // toggle selected item
      const nodeId = scoringTree.scoring.nodes[itemId].id

      const topLowestType = rankingType === reportRankingTypeMap.best ? 'top_selected_ids' : 'lowest_selected_ids'

      const currTopLowestIds = [...updatedTopLowest[section][topLowestType]]
      const isExceedLimit = currTopLowestIds.length >= reportConf.topLowestNum

      if (this.isTopLowest()) updatedTopLowest[section][topLowestType] = currTopLowestIds.filter(id => id !== nodeId)
      // remove
      else if (!isExceedLimit) updatedTopLowest[section][topLowestType].push(nodeId)
      // add
      else this.addMessageExceeded() // exceeded selection limit

      // check changing
      const isChanged = currTopLowestIds.length !== updatedTopLowest[section][topLowestType].length

      if (isChanged) {
        // remove Message Exceed
        this.removeMessageExceeded()

        // remove save check
        updatedTopLowest[section].saved = false

        // update changing
        updateReportTopLowest(updatedTopLowest, reportId)
      }
    }
  }

  /*-----------------------------------------------------*/
  // Get Check Status
  /*-----------------------------------------------------*/

  isTopLowest = () => {
    const { scoringTree, itemId, topLowest, section } = this.props

    const nodeId = scoringTree.scoring.nodes[itemId].id
    const output =
      topLowest &&
      section &&
      topLowest[section] &&
      ((topLowest[section].lowest_selected_ids && topLowest[section].lowest_selected_ids.includes(nodeId)) ||
        (topLowest[section].top_selected_ids && topLowest[section].top_selected_ids.includes(nodeId)))

    return output
  }

  /*-----------------------------------------------------*/
  // Add Error Message Check exceeded limit
  /*-----------------------------------------------------*/

  addMessageExceeded = () => {
    const { itemId, reportMessages, updateReportMessages } = this.props

    const updatedReportMessages = { ...reportMessages }

    if (!updatedReportMessages.top_lowest) updatedReportMessages.top_lowest = {}

    updatedReportMessages.top_lowest.exceeded = itemId

    updateReportMessages(updatedReportMessages)
  }

  removeMessageExceeded = () => {
    const { reportMessages, updateReportMessages } = this.props

    const updatedReportMessages = { ...reportMessages }

    if (updatedReportMessages && updatedReportMessages.top_lowest) delete updatedReportMessages.top_lowest.exceeded

    updateReportMessages(updatedReportMessages)
  }

  /*-----------------------------------------------------*/
  // Check Rendering
  /*-----------------------------------------------------*/

  render() {
    return (
      <Checkbox
        className="report-checkbox"
        onClick={event => this.toggleTopLowestSelection(event)}
        size="small"
        checked={this.isTopLowest()}
        color="primary"
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    scoringTree: state.scoringTree,
    reportId: state.report.reportObj.id,
    topLowest: state.report.reportObj.top_lowest,
    reportMessages: state.report.reportMessages,
  }
}

export default connect(mapStateToProps, actionCreators)(ReportTopLowestSelect)
