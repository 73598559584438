import { List, ListItem } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../store/actions'
import reportRankingTypeMap from '../../helper/reportRankingTypeMap'
import ReportFilterList from '../commons/reportFilterList'
import ReportSelect from '../commons/reportSelect'
import ReportDetailScoreItem from './reportDetailScoreItem'

const uuidv4 = require('uuid/v4')

export class ReportDetailScoreList extends React.Component {
  /*-----------------------------------------------------*/
  // Calculate List items with given limmit or special action
  /*-----------------------------------------------------*/

  getListItems = (sectionNode, limit, action, actionValue) => {
    // get list items minus or majior a limit value
    const { scoringTree, variant, nodes } = this.props
    const { nodeDefsObj, scoring } = scoringTree
    const { _left, _right } = sectionNode
    let items = []
    if (sectionNode.type === 'perimeter' || sectionNode.type === 'family') {
      items = Object.keys(nodes).filter(
        nodeDefId =>
          nodeDefsObj[nodeDefId] &&
          nodeDefsObj[nodeDefId]._left > _left &&
          nodeDefsObj[nodeDefId]._left < _right &&
          ((scoring.nodes[nodeDefId].percentage >= limit && variant === reportRankingTypeMap.best) ||
            (scoring.nodes[nodeDefId].percentage < limit && variant !== reportRankingTypeMap.best)) &&
          nodeDefsObj[nodeDefId].type === 'item'
      )
    } else if (sectionNode.type === 'subfamily') {
      return sectionNode.children_ids.filter(
        nodeDefId =>
          scoring.nodes[nodeDefId].is_enabled &&
          ((scoring.nodes[nodeDefId].percentage >= limit && variant === reportRankingTypeMap.best) ||
            (scoring.nodes[nodeDefId].percentage < limit && variant !== reportRankingTypeMap.best)) &&
          nodeDefsObj[nodeDefId].type === 'item'
      )
    }

    // order list items
    items = items.sort((aId, bId) => {
      const a = scoringTree.nodeDefsObj[aId]
      const b = scoringTree.nodeDefsObj[bId]
      if (a._left < b._left) return -1
      if (a._left > b._left) return 1
      return 0
    })

    // additional special filters to the list
    if (action === 'top' || action === 'worst') {
      const scoringChart = items
        .filter(id => nodeDefsObj[id].type === 'item')
        .sort((aId, bId) => {
          const a = scoringTree.scoring.nodes[aId]
          const b = scoringTree.scoring.nodes[bId]
          if (a.percentage < b.percentage) return -1
          if (a.percentage > b.percentage) return 1
          return 0
        })

      if (action === 'worst') return scoringChart.splice(0, actionValue)
      if (action === 'top') return scoringChart.splice(-1 * actionValue)
    }

    return items
  }

  /*-----------------------------------------------------*/
  // List rendering
  /*-----------------------------------------------------*/

  render() {
    const { section, limit, variant, texts } = this.props

    let itemList = []
    let itemFilteredList = []

    itemList = this.getListItems(section, 50)

    // get items when limit is a number
    if (Number.isInteger(limit)) itemFilteredList = this.getListItems(section, limit)
    else if (typeof limit === 'string' && limit.split(' ').length > 1) {
      // get items with specific action
      // if limit it's a string
      const [action, actionValue] = limit.split(' ')
      if (parseInt(actionValue, 10))
        itemFilteredList = this.getListItems(section, 50, action, parseInt(actionValue, 10))
    }

    return (
      <div className={`report-score-list ${variant}`}>
        <div className="report-score-list-header flex">
          <div className="report-score-title">
            {variant === reportRankingTypeMap.best ? texts.report_best : texts.report_worst}
          </div>
          <div className="report-score-actions flex">
            <ReportSelect variant={variant} list={itemList} values={[5, 10, 20]} />
            {variant === reportRankingTypeMap.best && (
              <ReportFilterList
                variant={variant}
                sectionId={section.id}
                values={[50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 'top 10']}
              />
            )}
            {variant === reportRankingTypeMap.worst && (
              <ReportFilterList
                variant={variant}
                sectionId={section.id}
                values={[5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 'worst 10']}
              />
            )}
          </div>
        </div>
        <List className="report-score-list-inner">
          {itemFilteredList.map(itemId => {
            return (
              <ListItem key={uuidv4()}>
                <ReportDetailScoreItem itemId={itemId} />
              </ListItem>
            )
          })}
        </List>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    scoringTree: state.scoringTree,
    nodes: state.report.reportObj.nodes,
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, actionCreators)(ReportDetailScoreList)
