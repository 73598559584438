import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../store/actions'
import reportConf from '../../helper/reportConf'
import ReportField from '../commons/reportField'
import { isFamilyScored } from '../utils'
import ReportSummaryEvaluation from './reportSummaryEvaluation'
import './styles.scss'

const uuidv4 = require('uuid/v4')

export class ReportSummaryContent extends React.Component {
  render() {
    const { texts, scoringTree } = this.props

    return (
      <>
        <div className="report-summary-content report-content-inner">
          {/*
          Report Title & General Comment
          */}

          <div className="report-summary-title">
            <ReportField id="report_title" label={texts.report_title} />
          </div>

          <div className="report-summary-comments ">
            <ReportField
              id="report_general_comment"
              multiline
              maxChar={reportConf.commentMaxChar}
              label={texts.report_general_comment}
            />
          </div>

          {/*
          Report Summary Sections 
          */}

          <div className="report-summary-evaluation-list">
            <div className="report-summary-perimeter-title label-l">{texts.report_perimeter_evaluation}</div>

            {reportConf.reportSummaryIndex.map(summaryRow => {
              if (
                !summaryRow.hide_unscored ||
                (summaryRow.hide_unscored && isFamilyScored(scoringTree, summaryRow.slug))
              )
                return (
                  <ReportSummaryEvaluation
                    section={summaryRow.slug}
                    picture={summaryRow.picture}
                    score={summaryRow.score}
                    topLowest={summaryRow.top_lowest}
                    key={uuidv4()}
                  />
                )

              return false
            })}
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    scoringTree: state.scoringTree,
  }
}

export default connect(mapStateToProps, actionCreators)(ReportSummaryContent)
