import { Breadcrumbs } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { isArray } from 'lodash'
import * as actionCreators from '../../../../store/actions'
import nodeDefinitionTypeMap from '../../../scoring_tree/helper/nodeDefinitionTypeMap'
// import BreadcrumbIcon from '../../../../assets/svg/lightbox-breadcrumb.svg'

const uuidv4 = require('uuid/v4')

export class ReportBreadcrumb extends React.Component {
  getBreadcrumb = (node, breadcrumb) => {
    const { scoringTree, environment } = this.props

    if (!breadcrumb) breadcrumb = []

    if (node.name) {
      const nodeName = node.name[environment.lang] ? node.name[environment.lang] : node.name[environment.defaultLang]
      breadcrumb.unshift(nodeName)
    }

    if (node.parent_id) breadcrumb = this.getBreadcrumb(scoringTree.nodeDefsObj[node.parent_id], breadcrumb)

    return breadcrumb
  }

  getBreadcrumbToRender = (breadcrumb, shortName) => {
    if (!shortName && !isArray(shortName)) return breadcrumb

    const breadcrumbToRender = [...breadcrumb]

    shortName.forEach(indexSlug => {
      const index = Object.values(nodeDefinitionTypeMap).indexOf(indexSlug)
      const nameToRender = breadcrumbToRender[index].split(' ')[0]
      breadcrumbToRender[index] = nameToRender
    })

    return breadcrumbToRender
  }

  getBreadcrumbStructured = (breadcrumb, structure) => {
    if (!structure && !isArray(structure)) return breadcrumb

    const breadcrumbStructured = structure.map(indexSlug => {
      const index = Object.values(nodeDefinitionTypeMap).indexOf(indexSlug)
      return breadcrumb[index]
    })

    return breadcrumbStructured
  }

  render() {
    const { node, structure, shortName } = this.props
    const breadcrumb = this.getBreadcrumb(node)
    const breadcrumbToRender = this.getBreadcrumbToRender(breadcrumb, shortName)
    const breadcrumbStructured = this.getBreadcrumbStructured(breadcrumbToRender, structure)

    return (
      <Breadcrumbs
        className="report-breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbStructured.map(item => (
          <span key={uuidv4()}>{item}</span>
        ))}
      </Breadcrumbs>
    )
  }
}

const mapStateToProps = state => {
  return {
    scoringTree: state.scoringTree,
    environment: state.environment,
  }
}

export default connect(mapStateToProps, actionCreators)(ReportBreadcrumb)
