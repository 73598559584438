import { ListItem } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../store/actions'

export class ReportMediaListSelector extends React.Component {
  /*-----------------------------------------------------*/
  //  Render Image Selector with counter
  /*-----------------------------------------------------*/

  toggleMediaSelect = () => {
    const { media, maxNumSelect, reportObj, updateReportScoreNodes, mediaNodeDef } = this.props
    const { nodes } = reportObj

    if (media) {
      const nodeDefId = mediaNodeDef.id

      if (nodes[nodeDefId] && nodes[nodeDefId].media_selected_ids) {
        const index = nodes[nodeDefId].media_selected_ids.indexOf(media.id)

        // Add Media
        if (index === -1 && (!maxNumSelect || nodes[nodeDefId].media_selected_ids.length < maxNumSelect))
          nodes[nodeDefId].media_selected_ids.push(media.id)
        // Stay at max number of media selectionable
        else if (index === -1 && maxNumSelect && nodes[nodeDefId].media_selected_ids.length >= maxNumSelect)
          nodes[nodeDefId].media_selected_ids[nodes[nodeDefId].media_selected_ids.length - 1] = media.id
        // Remove Media
        else nodes[nodeDefId].media_selected_ids.splice(index, 1)

        // update Report Scoring Nodes
        updateReportScoreNodes(nodes, reportObj.id)
      }
    }
  }

  /*-----------------------------------------------------*/
  //  Render Image Selector with counter
  /*-----------------------------------------------------*/

  render() {
    const { reportObj, media, mediaNodeDef } = this.props
    const { nodes } = reportObj

    const nodeDefId = mediaNodeDef.id

    const selected =
      nodes[nodeDefId] && nodes[nodeDefId].media_selected_ids && nodes[nodeDefId].media_selected_ids.includes(media.id)

    const index = selected ? nodes[nodeDefId].media_selected_ids.indexOf(media.id) + 1 : ''

    return (
      <ListItem button onClick={() => this.toggleMediaSelect()} className="report-media-selector" selected={selected}>
        <div className="report-media-counter">{index}</div>
      </ListItem>
    )
  }
}

const mapStateToProps = state => {
  return {
    authentication: state.authentication,
    reportObj: state.report.reportObj,
  }
}

export default connect(mapStateToProps, actionCreators)(ReportMediaListSelector)
