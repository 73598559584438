import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../store/actions'
import ReportField from '../commons/reportField'
import ReportPicture from '../commons/reportPicture'
import ScorePanel from '../../../commons/score_panel'
import ReportTopLowestPopup from './reportTopLowest/reportTopLowestPopup'
import reportConf from '../../helper/reportConf'

export class ReportSummaryEvaluation extends React.Component {
  render() {
    const { scorePanelObj, environment, score, picture, section, texts, topLowest } = this.props

    const summaryRowSlug = `report_${section}_evaluation`
    const label = texts[summaryRowSlug]
    const field = summaryRowSlug

    return (
      <div className="report-summary-evaluation flex">
        <div className="report-evaluation-picture">
          <ReportPicture picture={picture} />
        </div>
        <div className="report-evaluation-score">
          <ScorePanel
            scoreKey={score}
            scorePanel={scorePanelObj}
            maxProductScore={environment.config.max_product_score}
            // scoreData={scorePanelExtra}
          />
        </div>
        <div className="report-evaluation-field">
          {topLowest && <ReportTopLowestPopup section={section} />}
          <ReportField id={field} multiline maxChar={reportConf.evaluationMaxChar} secondaryLabel label={label} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    environment: state.environment,
    scorePanelObj: state.scoringTree.scoring.score_panel,
    scoringTree: state.scoringTree,
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, actionCreators)(ReportSummaryEvaluation)
