// import { navigate } from 'gatsby'
import React from 'react'
import { connect } from 'react-redux'

import _ from 'lodash'
import * as actionCreators from '../../../../store/actions'
import reportConf from '../../helper/reportConf'
import nodeDefinitionTypeMap from '../../../scoring_tree/helper/nodeDefinitionTypeMap'

export class ReportPageCounter extends React.Component {
  render() {
    const { nodes, scoringTree } = this.props

    const itemsSelectedCount = Object.keys(nodes).filter(
      nodeId =>
        nodes[nodeId].selected === 'enabled' &&
        scoringTree.nodeDefsObj[nodeId] &&
        scoringTree.nodeDefsObj[nodeId].type === 'item'
    ).length

    const subfamilyCount = Object.keys(nodes).filter(
      nodeId => scoringTree.nodeDefsObj[nodeId] && scoringTree.nodeDefsObj[nodeId].type === 'subfamily'
    ).length

    const demeritSubfamiliesCount = _.filter(scoringTree.nodeDefsObj, nodeDefinition => {
      return nodeDefinition.bonus_demerit && nodeDefinition.type === nodeDefinitionTypeMap.subfamily
    }).length

    const detailCount = itemsSelectedCount + subfamilyCount + reportConf.detailedBasePageCount - demeritSubfamiliesCount
    const summaryCount = reportConf.summaryPageCount
    const totalCount = detailCount + summaryCount

    return (
      <div className="report-page-counter">
        <div>Total: {totalCount} p.</div>
        <div>Summary: {summaryCount} p.</div>
        <div>Detailed: {detailCount} p.</div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    nodes: state.report.reportObj.nodes,
    scoringTree: state.scoringTree,
  }
}

export default connect(mapStateToProps, actionCreators)(ReportPageCounter)
