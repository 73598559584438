import { Collapse, List, ListItem } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../store/actions'
import ExpandIcon from '../../../../assets/svg/expand-icon.svg'
import CollapseIcon from '../../../../assets/svg/collapse-icon.svg'
import ReportMediaList from '../commons/reportMediaList'
import ReportCheckbox from '../commons/reportCheckbox'

export class ReportDetailScoreItem extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      expanded: false,
    }
  }

  /*-----------------------------------------------------*/
  // Toggle Collapse
  /*-----------------------------------------------------*/

  toggleItem = () => {
    // update state
    const { expanded } = this.state
    this.setState({ expanded: !expanded })
    // this.setState({ loading: false })
  }

  /*-----------------------------------------------------*/
  // Item Media
  /*-----------------------------------------------------*/

  getItemMedia = itemId => {
    const { media } = this.props
    return media.filter(currMedia => currMedia.node_definition_id === itemId)
  }

  /*-----------------------------------------------------*/
  // Index Detail Handlers: Check and Click
  /*-----------------------------------------------------*/

  render() {
    const { itemId, environment, scoringTree } = this.props
    const { expanded } = this.state
    const item = scoringTree.nodeDefsObj[itemId]
    const itemScore = `${Math.round(scoringTree.scoring.nodes[itemId].percentage)}%`
    const itemName = item.name[environment.lang] ? item.name[environment.lang] : item.name[environment.defaultLang]
    const itemMediaList = this.getItemMedia(itemId)
    const itemMediaListCount = itemMediaList.length

    return (
      <List disablePadding className="report-score-item">
        <ListItem button onClick={() => this.toggleItem()} className="report-score-item-title flex">
          <ReportCheckbox itemId={itemId} />

          <div className="report-score-item-name">{itemName}</div>

          {itemScore && <div className="report-score-item-value">{itemScore}</div>}

          {itemMediaListCount > 0 && expanded && <CollapseIcon />}
          {itemMediaListCount > 0 && !expanded && <ExpandIcon />}
        </ListItem>
        {itemMediaListCount > 0 && (
          <Collapse in={expanded} timeout="auto" unmountOnExit className="report-score-item-media-list">
            {itemMediaListCount > 0 && <ReportMediaList mediaNodeDef={item} maxNumSelect={5} />}
          </Collapse>
        )}
      </List>
    )
  }
}

const mapStateToProps = state => {
  return {
    environment: state.environment,
    scoringTree: state.scoringTree,
    media: state.report.media,
  }
}

export default connect(mapStateToProps, actionCreators)(ReportDetailScoreItem)
