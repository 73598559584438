import React from 'react'
import ReportActions from '../commons/reportActions'

export class ReportSummaryOnlyNavbar extends React.Component {
  render() {
    return (
      <>
        <div className="report-navbar-left" />
        <ReportActions variant="summary" />
      </>
    )
  }
}

export default ReportSummaryOnlyNavbar
