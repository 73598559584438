// import { navigate } from 'gatsby'
import { Button } from '@material-ui/core'
import { navigate } from 'gatsby'
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../store/actions'
import reportConf from '../../helper/reportConf'

export class ReportPageButton extends React.Component {
  render() {
    const { buttonText, updateReportPage, targetReportPage, color, scoringTree } = this.props
    const pageLink = `/${reportConf.slug}?id=${scoringTree.scoring.id}&view=${targetReportPage}`

    return (
      <Button
        variant="contained"
        color={color || 'secondary'}
        onClick={() => {
          navigate(pageLink)
          updateReportPage(targetReportPage)
        }}
      >
        {buttonText}
      </Button>
    )
  }
}

const mapStateToProps = state => {
  return {
    scoring: state.scoring,
    scoringTree: state.scoringTree,
  }
}

export default connect(mapStateToProps, actionCreators)(ReportPageButton)
