import { Select } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux'
import ExpandMoreSharpIcon from '@material-ui/icons/ExpandMoreSharp'
import * as actionCreators from '../../../../store/actions'
import { updateParentsCheckboxSelection } from '../utils'
import reportRankingTypeMap from '../../helper/reportRankingTypeMap'

const uuidv4 = require('uuid/v4')

export class ReportSelect extends React.Component {
  getScoringChart = value => {
    const { scoringTree, list, nodes, variant } = this.props
    const { nodeDefsObj } = scoringTree

    const nodesList = list || Object.keys(nodes)

    const scoringChart = nodesList
      .filter(
        id => nodeDefsObj[id] && nodeDefsObj[id].type === 'item'
        /* && scoringTree.scoring.nodes[nodeId].is_enabled */
      )
      .sort((aId, bId) => {
        const a = scoringTree.scoring.nodes[aId]
        const b = scoringTree.scoring.nodes[bId]
        if (a.percentage < b.percentage) return -1
        if (a.percentage > b.percentage) return 1
        return 0
      })

    const worstIds = variant !== reportRankingTypeMap.best ? scoringChart.slice(0, value) : []

    const bestIds = variant !== reportRankingTypeMap.worst ? scoringChart.slice(-1 * value) : []

    // ids selected for update best/worst N
    const bestWrostIds = [...worstIds, ...bestIds].sort()

    return bestWrostIds
  }

  /*-----------------------------------------------------*/
  // Select Checkbox Nodes
  /*-----------------------------------------------------*/

  chartNodeSelection = value => {
    const { updateReportScoreNodes, reportId, nodes, scoringTree, list } = this.props

    const { nodeDefsObj } = scoringTree

    // consider a list of node ids or all the nodes
    const nodesList = list || Object.keys(nodes)

    // get scoring chart
    let updatedNodeList = { ...nodes }

    if (value === 0 || value === '0') {
      // deselect all
      nodesList.forEach(nodeId => {
        if (updatedNodeList[nodeId]) {
          updatedNodeList[nodeId].selected = 'disabled'
        }
      })
    } else {
      // select in a chart best or wrost n value

      const updatedSelectChartIds = this.getScoringChart(value)

      // select top nodes in a list
      nodesList.forEach(nodeId => {
        if (updatedNodeList[nodeId]) {
          if (updatedSelectChartIds.includes(nodeId)) updatedNodeList[nodeId].selected = 'enabled'
          else updatedNodeList[nodeId].selected = 'disabled'
        }
      })
    }

    // update parents
    updatedNodeList = updateParentsCheckboxSelection(updatedNodeList, nodeDefsObj)

    // update global Node selected list
    updateReportScoreNodes(updatedNodeList, reportId)
  }

  /*-----------------------------------------------------*/
  // Get Values
  /*-----------------------------------------------------*/

  isValue = value => {
    const { nodes, scoringTree, list } = this.props
    const { nodeDefsObj } = scoringTree

    const chartByValueIds = this.getScoringChart(value)

    const nodesList = list || Object.keys(nodes)

    const itemsSelected = nodesList
      .filter(
        nodeId =>
          nodeDefsObj[nodeId] &&
          nodeDefsObj[nodeId].type === 'item' &&
          nodes[nodeId] &&
          nodes[nodeId].selected === 'enabled'
      )
      .sort()

    return (
      (chartByValueIds.length === itemsSelected.length &&
        chartByValueIds.every((item, index) => itemsSelected[index] === item)) ||
      (itemsSelected.length === 0 && value === 0)
    )
  }

  getValue = valuesList => {
    const updateValuesList = [...valuesList]
    const currValue = updateValuesList.pop()

    if (typeof currValue === 'undefined' || currValue === '') return ''

    if (this.isValue(currValue)) return currValue
    return this.getValue(updateValuesList)
  }

  /*-----------------------------------------------------*/
  // Render Global Select
  /*-----------------------------------------------------*/

  render() {
    const { texts, values, variant, list, nodes } = this.props

    const nodesList = list || Object.keys(nodes)

    const valuesList = values && Array.isArray(values) ? values.filter(value => value <= nodesList.length) : ['']
    const menuItemsValues = [0, ...valuesList]

    let prefix = texts.report_best_worst
    if (variant) prefix = variant

    return (
      <div className="report-select">
        <span className="report-select-label">Select</span>
        <Select
          value={this.getValue(valuesList)}
          onChange={event => this.chartNodeSelection(event.target.value)}
          onClick={event => this.chartNodeSelection(event.target.value)}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          IconComponent={ExpandMoreSharpIcon}
          native
        >
          {menuItemsValues.map(menuItemsValue => {
            const menuItemsText = menuItemsValue !== 0 ? `${prefix} ${menuItemsValue}` : texts.report_none

            return (
              <option
                key={uuidv4()}
                value={menuItemsValue}
                disabled={menuItemsValue === ''}
                onSelect={event => console.log(event.target)}
              >
                {menuItemsText}
              </option>
            )
          })}
        </Select>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    reportId: state.report.reportObj.id,
    nodes: state.report.reportObj.nodes,
    scoringTree: state.scoringTree,
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, actionCreators)(ReportSelect)
