import React from 'react'
import { connect } from 'react-redux'
import './styles.scss'
import './_parts/commons/styles.scss'
import * as actionCreators from '../../store/actions'
import { checkIfScoringExistsOnServer, invisibleDownloadScoringFromServer } from '../../../va-corejs-v3/actions/scoring'
import { isEmptyObject } from '../../../va-corejs-v3/utils'
import { LoadingBar } from '../loading_bar'
import ReportContent from './_parts/commons/reportContent'

export class reportComponent extends React.Component {
  state = {
    loaded: false,
  }

  /*-----------------------------------------------------*/
  // Init Scoring And Current Page
  /*-----------------------------------------------------*/

  componentDidMount = async () => {
    const {
      getScoringTreeById,
      updateDownloadedScorings,
      processSyncQueue,
      updateReportPage,
      device,
      initReportMedia,
      initReportData,
      initReportDetailSection,
      location,
      doCalculationAndSync,
    } = this.props

    /*-----------------------------------------------------*/
    // QUERY URL PARAMETER
    /*-----------------------------------------------------*/

    const pageReportUrl = new URLSearchParams(location.search)
    const pageReportScoringId = pageReportUrl.get('id')
    const pageReportView = pageReportUrl.get('view') !== null ? pageReportUrl.get('view') : 0

    if (pageReportScoringId) {
      /*-----------------------------------------------------*/
      // Calculate Scoring Tree
      // On refresh it check if scoring is new then
      // store all nodes into sync_queue to sync them
      /*-----------------------------------------------------*/

      if (device.isOnline) {
        const scoringExistsOnServer = await checkIfScoringExistsOnServer(pageReportScoringId)
        if (scoringExistsOnServer) {
          await invisibleDownloadScoringFromServer(pageReportScoringId)
          await updateDownloadedScorings(pageReportScoringId)
        }
      }

      await getScoringTreeById(pageReportScoringId)

      const { scoringTree, templates, initializeScoringForEdit, authentication } = this.props
      const { scoring, nodeDefsObj } = scoringTree
      const { nodes } = scoring

      // Do a complete calculation of the scoring and sync the new data
      await doCalculationAndSync(scoring, nodeDefsObj, nodes)
      if (device.isOnline) {
        processSyncQueue()
      }

      // init scoring for edit (scoring pictures ecc..)
      // in order to get summary images
      const { scope } = templates.list.filter(x => x.id === scoring.template_id)[0]

      await initializeScoringForEdit(scoringTree, scope)

      /*-----------------------------------------------------*/
      // INIT REPORT OBJECT
      /*-----------------------------------------------------*/

      // init view state
      initReportDetailSection(scoringTree)
      initReportMedia(scoringTree)

      // init data state
      await initReportData(scoringTree, authentication.user.id)
    }

    if (pageReportView) updateReportPage(parseInt(pageReportView, 10))

    this.setState({ loaded: true })
  }

  componentDidUpdate = () => {
    const { updateReportPage, location } = this.props

    const pageReportUrl = new URLSearchParams(location.search)
    const pageReportView = pageReportUrl.get('view') !== null ? pageReportUrl.get('view') : 0

    if (pageReportView) updateReportPage(parseInt(pageReportView, 10))
  }

  /*-----------------------------------------------------*/
  // Render Page and switch between Summary and Detail Views
  /*-----------------------------------------------------*/

  render() {
    const { loaded } = this.state
    const { scoringTree, texts, reportPage } = this.props

    return (
      <div className="report-main">
        {!loaded && (
          <div className="scoring_mode_loader_outer_container">
            <div className="scoring_mode_loader_inner_container">
              <LoadingBar />
            </div>
          </div>
        )}
        {loaded && !isEmptyObject(scoringTree.scoring) && (
          <div className="report-container">
            <ReportContent page={reportPage} />
          </div>
        )}
        {loaded && isEmptyObject(scoringTree.scoring) && (
          <div className="scoring_not_found_container">
            <div className="scoring_not_found_404">{texts.error_404}</div>
            <div className="scoring_not_found_message">{texts.product_not_found_message}</div>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    environment: state.environment,
    device: state.device,
    texts: state.texts.values,
    scoring: state.scoring,
    scoringTree: state.scoringTree,
    reportPage: state.report.reportPage,
    templates: state.templates,
    authentication: state.authentication,
  }
}

export default connect(mapStateToProps, actionCreators)(reportComponent)
