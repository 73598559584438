import { Switch } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../store/actions'

export class ReportSelectAll extends React.Component {
  constructor(props) {
    super(props)
    const { nodes } = this.props
    const selected = !Object.keys(nodes).some(nodeId => nodes[nodeId].selected === 'disabled')
    this.state = {
      selected,
    }
  }

  componentDidUpdate(previousProps) {
    const { nodes } = this.props
    if (nodes !== previousProps.nodes) {
      const selected = !Object.keys(nodes).some(nodeId => nodes[nodeId].selected === 'disabled')
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ selected })
    }
  }

  /*-----------------------------------------------------*/
  // Select All Nodes
  /*-----------------------------------------------------*/

  allNodeSelection = () => {
    const { updateReportScoreNodes, reportId, nodes } = this.props
    const { selected } = this.state
    const updatedNodeList = { ...nodes }

    // update the state
    this.setState({ selected: !selected })

    if (!selected) {
      // select all nodes
      Object.keys(updatedNodeList).forEach(nodeId => {
        updatedNodeList[nodeId].selected = 'enabled'
      })
    } else {
      // unselect all nodes
      Object.keys(updatedNodeList).forEach(nodeId => {
        updatedNodeList[nodeId].selected = 'disabled'
      })
    }

    // update global Node selected list
    updateReportScoreNodes(updatedNodeList, reportId)
  }

  /*-----------------------------------------------------*/
  // Render Select All
  /*-----------------------------------------------------*/

  render() {
    const { texts } = this.props
    const { selected } = this.state

    return (
      <div className="report-select-all">
        <span className="report-select-all-label">
          {selected ? texts.report_unselect_all : texts.report_select_all}
        </span>
        <Switch
          disableRipple
          className="report-switch"
          color="primary"
          checked={selected}
          onChange={() => this.allNodeSelection()}
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    reportTexts: state.report.reportObj.texts,
    reportId: state.report.reportObj.id,
    nodes: state.report.reportObj.nodes,
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, actionCreators)(ReportSelectAll)
