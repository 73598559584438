import { Checkbox } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../store/actions'
import nodeDefinitionTypeMap from '../../../scoring_tree/helper/nodeDefinitionTypeMap'
import { updateParentsCheckboxSelection } from '../utils'

const classNames = require('classnames')

export class ReportCheckbox extends React.Component {
  /*-----------------------------------------------------*/
  // Check/Uncheck Event
  /*-----------------------------------------------------*/

  toggleNodeSelection = event => {
    // stop propagation in order to don't see the node detail
    // or open submenu when check it

    event.stopPropagation()

    const { updateReportScoreNodes, itemId, report, scoringTree } = this.props
    const { nodes, id: reportId } = report

    let updatedNodeList = { ...nodes }

    if (updatedNodeList[itemId]) {
      // toggle selected item or check it if indeterminate
      updatedNodeList[itemId].selected = updatedNodeList[itemId].selected === 'enabled' ? 'disabled' : 'enabled'

      // toggle all children
      updatedNodeList = this.toggleAllChildrenSelection(updatedNodeList[itemId].selected, itemId, updatedNodeList)

      // update parents
      updatedNodeList = updateParentsCheckboxSelection(updatedNodeList, scoringTree.nodeDefsObj)

      // update global Node selected list
      updateReportScoreNodes(updatedNodeList, reportId)
    }
  }

  /*-----------------------------------------------------*/
  // Select all children or deselect it
  /*-----------------------------------------------------*/

  toggleAllChildrenSelection(selected, nodeId, nodeList) {
    const { scoringTree } = this.props
    const { nodeDefsObj } = scoringTree
    const children = nodeDefsObj[nodeId].children_ids.filter(id => nodeList[id])

    if (children)
      children.forEach(childrenId => {
        if (nodeList[childrenId]) nodeList[childrenId].selected = selected
        nodeList = this.toggleAllChildrenSelection(selected, childrenId, nodeList)
      })

    return nodeList
  }

  /*-----------------------------------------------------*/
  // Check Rendering
  /*-----------------------------------------------------*/

  render() {
    const { itemId, report, scoringTree } = this.props
    const { nodeDefsObj: nodeDefinitions } = scoringTree
    const { nodes } = report
    const currentReportNode = nodes[itemId]
    const currentNodeDefinition = nodeDefinitions[currentReportNode.node_definition_id]

    // item checked
    const selected = currentReportNode ? currentReportNode.selected : 'disabled'
    const checked = selected !== 'indeterminate' ? selected : 'disabled'
    const indeterminate = selected === 'indeterminate'
    const filled = () =>
      ((currentReportNode.text !== null && currentReportNode.text !== '') ||
        currentReportNode.media_selected_ids.length > 0) &&
      currentNodeDefinition.type === nodeDefinitionTypeMap.subfamily

    return (
      <Checkbox
        className={classNames({
          'report-checkbox': true,
          'report-checkbox--filled': filled(),
        })}
        color={filled() ? 'default' : 'primary'}
        onClick={event => this.toggleNodeSelection(event)}
        size="small"
        checked={indeterminate || checked === 'enabled'}
        indeterminate={indeterminate}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    scoringTree: state.scoringTree,
    report: state.report.reportObj,
  }
}

export default connect(mapStateToProps, actionCreators)(ReportCheckbox)
