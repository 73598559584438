import React from 'react'
import { connect } from 'react-redux'
import reportPageMap from '../../helper/reportPageMap'

import * as actionCreators from '../../../../store/actions'
import ReportPageButton from '../commons/reportPageButton'
import ReportActions from '../commons/reportActions'

export class ReportDetailNavbar extends React.Component {
  render() {
    const { texts } = this.props
    return (
      <>
        <div className="report-navbar-left">
          <ReportPageButton buttonText={texts.report_summary_edit} targetReportPage={reportPageMap.summary} />
        </div>
        <ReportActions variant="detailed" />
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, actionCreators)(ReportDetailNavbar)
