import { Backdrop, Button, Fade, Modal } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import * as actionCreators from '../../../../store/actions'
import AddMedia from '../../../../assets/svg/add-media.svg'
import ReportMediaList from './reportMediaList'
import ReportBreadcrumb from './reportBreadcrumb'

export class ReportPopupMedia extends React.Component {
  state = {
    open: false,
  }

  popupOpen = () => {
    this.setState({ open: true })
  }

  popupClose = () => {
    this.setState({ open: false })
  }

  hasMedia = node => {
    const { media, reportNodes, scoringTree } = this.props
    const { nodeDefsObj: nodeDefinitions } = scoringTree

    const filteredNodeDefinitions = _.filter(nodeDefinitions, _nodeDefinition => {
      return node._left < _nodeDefinition._left && _nodeDefinition._right < node._right
    })

    const filteredIds = Object.keys(_.keyBy(filteredNodeDefinitions, 'id'))

    if (filteredIds.filter(id => reportNodes[id])) {
      return media.map(obj => obj.node_definition_id).some(e => filteredIds.filter(id => reportNodes[id]).includes(e))
    }

    return media.map(obj => obj.node_definition_id).includes(node.node_definition_id)
  }

  render() {
    const { popupNode, texts, maxNumSelect } = this.props
    const { open } = this.state
    const hasMedia = this.hasMedia(popupNode)

    return (
      <>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddMedia />}
          onClick={() => this.popupOpen()}
          className="report-popup-media-btn round-btn icon-btn"
          disabled={!hasMedia}
        />

        <Modal
          className="report-popup-media report-popup"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={() => this.popupClose()}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className="report-popup-window report-popup-media-window">
              <div className="report-popup-header flex">
                <span className="report-popup-header-text">{texts.report_subfamily_edit}:</span>

                <span className="report-popup-header-breadcrumb">
                  <ReportBreadcrumb node={popupNode} />
                </span>

                <Button color="primary" onClick={() => this.popupClose()} className="report-popup-cancel">
                  {texts.close}
                </Button>
              </div>

              <div className="report-popup-content">
                <div className="report-popup-media-list">
                  <ReportMediaList mediaNodeDef={popupNode} maxNumSelect={maxNumSelect} />
                </div>
              </div>

              <div className="report-popup-footer">
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => this.popupClose()}
                  className="report-popup-save"
                >
                  {texts.save_changes}
                </Button>
              </div>
            </div>
          </Fade>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    authentication: state.authentication,
    texts: state.texts.values,
    media: state.report.media,
    reportNodes: state.report.reportObj.nodes,
    scoringTree: state.scoringTree,
  }
}

export default connect(mapStateToProps, actionCreators)(ReportPopupMedia)
