import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../store/actions'

import { searchProductsByTemplate } from '../../../../../va-corejs-v3/actions/elastic_search'

export class reportBenchmark extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pIdList: [],
      products: {},
    }
  }

  componentDidMount = async () => {
    const { scoringTree /* report */ } = this.props

    const results = await searchProductsByTemplate(scoringTree.scoring.template_id)

    if (results.status === 200) {
      // datalist
      const { data } = results
      const { hits } = data.hits
      const parsedProducts = await this.parseProductsFromElasticSearch(hits)
      const { pIdList, products } = parsedProducts

      this.setState({ pIdList, products })
    }
  }

  parseProductsFromElasticSearch = async hits => {
    const { scoringTree } = this.props
    const { scoring } = scoringTree

    const products = {}
    const pIdList = []
    for (let i = 0; i !== hits.length; i += 1) {
      const hit = hits[i]._source
      const { id, props } = hit
      if (id !== scoring.id) {
        pIdList.push(id)

        const brand = props.brand.value
        const model = props.model.value
        let name = `${brand} ${model}`

        if (props['commercial-name-grade']) {
          const commercialGrade = props['commercial-name-grade'].value
          name = `${brand} ${model} ${commercialGrade}`
        }

        products[id] = name
      }
    }
    return {
      pIdList,
      products,
    }
  }

  handleInputchanged = benchmarkName => {
    const { report, updateReportBenchmark, updateReportTexts } = this.props
    const { pIdList, products } = this.state

    let selectedProductId = 0
    for (let i = 0; i !== pIdList.length; i += 1) {
      const pID = pIdList[i]
      const pName = products[pID]
      if (pName === benchmarkName) {
        selectedProductId = pID
        break
      }
    }
    if (selectedProductId !== 0) {
      updateReportBenchmark(selectedProductId, report.id)
      updateReportTexts(benchmarkName, 'benchmark_name', report.texts, report.id)
    }
    if (selectedProductId === 0 && benchmarkName === '') {
      updateReportBenchmark(null, report.id)
      updateReportTexts('', 'benchmark_name', report.texts, report.id)
    }
  }

  render() {
    const { products } = this.state
    const { texts, report } = this.props
    const productIds = Object.keys(products)

    // default benchmark Name
    const benchmarkId = report.benchmark_id
    const benchmarkName = benchmarkId !== 0 ? products[benchmarkId] : ''

    return (
      <>
        <div className="benchmark_selector">
          <div className="benchmark_list_container">
            <label htmlFor="report_benchmark_input">
              <span className="benchmark_label">Benchmark product</span>
              <input
                id="report_benchmark_input"
                type="text"
                defaultValue={benchmarkName}
                placeholder={texts.report_select_benchmark}
                className="benchmark_input"
                list="benchmark_list"
                onChange={e => {
                  this.handleInputchanged(e.target.value)
                }}
              />
            </label>
            <datalist id="benchmark_list">
              {productIds.map(pID => {
                return (
                  <option key={pID} id={pID}>
                    {products[pID]}
                  </option>
                )
              })}
            </datalist>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    scoringTree: state.scoringTree,
    texts: state.texts.values,
    report: state.report.reportObj,
  }
}

export default connect(mapStateToProps, actionCreators)(reportBenchmark)
