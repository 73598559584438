import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../store/actions'
import reportPageMap from '../../helper/reportPageMap'
import ReportPageButton from '../commons/reportPageButton'

export class ReportSummaryNavbar extends React.Component {
  render() {
    const { texts } = this.props

    return (
      <>
        <div className="report-navbar-left" />
        <div className="report-navbar-right flex report-spacing">
          <ReportPageButton buttonText={texts.report_next} targetReportPage={reportPageMap.detail} color="primary" />
        </div>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, actionCreators)(ReportSummaryNavbar)
