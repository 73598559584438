import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../store/actions'
import ReportDetailIndex from './reportDetailIndex'
import ReportDetailScore from './reportDetailScore'
import './styles.scss'

export class ReportDetailContent extends React.Component {
  state = {
    scrolled: false,
  }

  scrollStickyHandler = e => {
    const { scrolled } = this.state

    if (e.target.scrollTop >= 2 && !scrolled) this.setState({ scrolled: true })
    if (e.target.scrollTop < 2 && scrolled) this.setState({ scrolled: false })
  }

  render() {
    const { reportSidebarVisible } = this.props
    const { scrolled } = this.state
    const scrolledClass = scrolled ? 'report-detail-scrolled' : ''
    const hideSidebarClass = reportSidebarVisible ? '' : 'report-sidebar-hidden'

    return (
      <div className={`${hideSidebarClass} ${scrolledClass} report-detail-content report-content-inner`}>
        <div className="report-detail-left">
          <ReportDetailIndex />
        </div>

        <div className="report-detail-right" onScroll={e => this.scrollStickyHandler(e)}>
          <ReportDetailScore />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    reportSidebarVisible: state.report.reportSidebarVisible,
  }
}

export default connect(mapStateToProps, actionCreators)(ReportDetailContent)
