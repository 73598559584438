import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../store/actions'
import ReportSelectAll from '../commons/reportSelectAll'
import ReportSelect from '../commons/reportSelect'
import ReportFilterGlobal from '../commons/reportFilterGlobal'
import ReportPageCounter from '../commons/reportPageCounter'

export class ReportDetailHeader extends React.Component {
  render() {
    const { texts } = this.props
    return (
      <>
        <div className="report-header-title">
          <div> {texts.report_detail}</div>
          <ReportPageCounter />
        </div>
        <div className="report-header-content flex">
          <ReportSelect values={[5, 10, 20]} />

          <ReportFilterGlobal
            min={50}
            max={100}
            step={5}
            variant="best"
            label="Filter Best"
            className="report-filter-best"
          />

          <ReportFilterGlobal min={0} max={50} step={5} variant="worst" label="Filter Worst" track="inverted" />

          <ReportSelectAll />
        </div>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, actionCreators)(ReportDetailHeader)
