import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../store/actions'
import ReportBenchmark from '../commons/reportBenchmark'

export class ReportSummaryHeader extends React.Component {
  render() {
    const { texts } = this.props
    return (
      <>
        <div className="report-header-title title-xl">{texts.report_summary}</div>
        <div className="report-header-content flex report-spacing">
          <div className="analytics_header_right">
            <ReportBenchmark />
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, actionCreators)(ReportSummaryHeader)
