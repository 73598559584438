import { Slider } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../store/actions'
import reportRankingTypeMap from '../../helper/reportRankingTypeMap'

const classNames = require('classnames')

export class ReportFilterGlobal extends React.Component {
  /*-----------------------------------------------------*/
  // Init Global Filter state
  /*-----------------------------------------------------*/

  constructor(props) {
    super(props)

    const { globalFilters, variant } = this.props

    this.state = {
      sliderValue: globalFilters[variant] || '',
      itemsCounter: '',
    }
  }

  /*-----------------------------------------------------*/
  // Select Filter
  /*-----------------------------------------------------*/

  updateFilter = (event, value) => {
    const {
      globalFilters,
      variant,
      updateReportFiltersGlobal,
      updateReportFiltersList,
      filters,
      scoringTree,
      nodes,
    } = this.props
    const updatedfilters = { ...globalFilters }

    // update global filter
    if (!updatedfilters[variant] || updatedfilters[variant] !== value) {
      updatedfilters[variant] = value
      updateReportFiltersGlobal(updatedfilters)

      // then reset all specific filters
      const updatedFiltersSpecific = { ...filters }
      let filtersUpdated = false

      Object.keys(updatedFiltersSpecific).forEach(id => {
        if (updatedFiltersSpecific[id][variant] && updatedFiltersSpecific[id][variant] !== '') {
          updatedFiltersSpecific[id][variant] = ''
          filtersUpdated = true
        }
      })

      if (filtersUpdated) updateReportFiltersList(updatedFiltersSpecific)

      // updateItemsCounter

      const limit = value || 50
      const updatedItemsCounter = Object.keys(nodes).filter(
        nodeId =>
          scoringTree.nodeDefsObj[nodeId] &&
          scoringTree.nodeDefsObj[nodeId].type === 'item' &&
          ((variant === reportRankingTypeMap.best && scoringTree.scoring.nodes[nodeId].percentage > limit) ||
            (variant === reportRankingTypeMap.worst && scoringTree.scoring.nodes[nodeId].percentage < limit))
      ).length

      this.setState({ itemsCounter: updatedItemsCounter })
    }

    this.setState({ sliderValue: value })
  }

  /*-----------------------------------------------------*/
  // Items Count
  /*-----------------------------------------------------*/

  getItemsCount = () => {
    const { nodes, scoringTree, globalFilters, variant } = this.props
    const { itemsCounter } = this.state

    if (nodes && Object.keys(nodes).length && !itemsCounter) {
      const limit = globalFilters[variant]
      const initItemsCounter = Object.keys(nodes).filter(
        nodeId =>
          scoringTree.nodeDefsObj[nodeId] &&
          scoringTree.nodeDefsObj[nodeId].type === 'item' &&
          ((variant === reportRankingTypeMap.best && scoringTree.scoring.nodes[nodeId].percentage > limit) ||
            (variant === reportRankingTypeMap.worst && scoringTree.scoring.nodes[nodeId].percentage < limit))
      ).length

      return initItemsCounter
    }
    return itemsCounter
  }

  /*-----------------------------------------------------*/
  // Render Global Select
  /*-----------------------------------------------------*/

  render() {
    const { min, max, step, track, defaultValue, globalFilters, variant, label, texts, className } = this.props
    const { sliderValue } = this.state

    let all = defaultValue
    if (variant === reportRankingTypeMap.best) all = min
    if (variant === reportRankingTypeMap.worst) all = max

    return (
      <div className={classNames('report-filter-global', className)}>
        <div className="report-filter-heading flex">
          <div className="report-filter-label">{label}:</div>
          <div className="report-filter-value">
            {globalFilters[variant] !== '' ? `${sliderValue}% (${this.getItemsCount()})` : texts.all}
          </div>
        </div>

        <Slider
          defaultValue={defaultValue}
          aria-labelledby="discrete-slider"
          step={step}
          marks
          min={min}
          max={max}
          track={track || 'normal'}
          value={globalFilters[variant] !== '' ? sliderValue : all}
          onChange={(event, value) => this.updateFilter(event, value)}
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    reportId: state.report.reportObj.id,
    nodes: state.report.reportObj.nodes,
    scoringTree: state.scoringTree,
    texts: state.texts.values,
    globalFilters: state.report.reportFiltersGlobal,
    filters: state.report.reportFiltersList,
  }
}

export default connect(mapStateToProps, actionCreators)(ReportFilterGlobal)
