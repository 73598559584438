import { Backdrop, Button, Fade, Modal } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../../store/actions'
import ReportTopLowestList from './reportTopLowestList'
import CheckIcon from '../../../../../assets/svg/button-desk-check.svg'
import reportRankingTypeMap from '../../../helper/reportRankingTypeMap'

export class ReportTopLowestPopup extends React.Component {
  state = {
    open: false,
  }

  /*-----------------------------------------------------*/
  // Save and close popup
  /*-----------------------------------------------------*/

  popupOpen = () => {
    this.setState({ open: true })
  }

  popupClose = () => {
    const { updateReportTopLowest, section, report } = this.props

    const updatedTopLowest = { ...report.top_lowest }
    if (!updatedTopLowest[section]) updatedTopLowest[section] = {}

    updateReportTopLowest(updatedTopLowest, report.id)

    // closing popup
    this.actionOnClosing()
    this.setState({ open: false })
  }

  popupSave = () => {
    const { updateReportTopLowest, section, report } = this.props

    const updatedTopLowest = { ...report.top_lowest }
    if (!updatedTopLowest[section]) updatedTopLowest[section] = {}
    updatedTopLowest[section].saved = true
    updatedTopLowest[section].is_default = false

    updateReportTopLowest(updatedTopLowest, report.id)

    // closing popup
    this.actionOnClosing()
    this.setState({ open: false })
  }

  actionOnClosing = () => {
    const { reportMessages, updateReportMessages } = this.props

    const updatedReportMessages = { ...reportMessages }

    // resetErrorMessages
    if (updatedReportMessages && updatedReportMessages.top_lowest) {
      delete updatedReportMessages.top_lowest
      updateReportMessages(updatedReportMessages)
    }
  }

  /*-----------------------------------------------------*/
  // Popup Rendering
  /*-----------------------------------------------------*/

  render() {
    const { texts, section, report } = this.props
    const { open } = this.state

    const topLowestSaved = report.top_lowest[section] && report.top_lowest[section].saved

    return (
      <>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => this.popupOpen()}
          className="round-btn btn-light btn-report-top-lowest"
        >
          {texts.report_set_top_lowest}
          {topLowestSaved && <CheckIcon className="report-validate-icon" />}
        </Button>

        <Modal
          className="report-top-lowest-popup report-popup"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={() => this.popupClose()}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className="report-popup-window">
              <div className="report-popup-header flex">
                <span className="report-popup-header-text">
                  {section.toUpperCase()} : {texts.report_top_lowest}
                </span>

                <Button color="primary" onClick={() => this.popupClose()} className="report-popup-cancel">
                  {texts.close}
                </Button>
              </div>

              <div className="report-popup-content">
                <div className="report-score-list-columns report-toplowest-content flex">
                  <ReportTopLowestList section={section} rankingType={reportRankingTypeMap.best} />
                  <ReportTopLowestList section={section} rankingType={reportRankingTypeMap.worst} />
                </div>
              </div>

              <div className="report-popup-footer">
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => this.popupSave()}
                  className="report-popup-save"
                >
                  {texts.save_changes}
                </Button>
              </div>
            </div>
          </Fade>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    authentication: state.authentication,
    texts: state.texts.values,
    media: state.report.media,
    report: state.report.reportObj,
    reportMessages: state.report.reportMessages,
    // scoringTree: state.scoringTree,
  }
}

export default connect(mapStateToProps, actionCreators)(ReportTopLowestPopup)
