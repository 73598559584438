import { Collapse, List, ListItem } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../store/actions'
import ExpandIcon from '../../../../assets/svg/expand-icon.svg'
import CollapseIcon from '../../../../assets/svg/collapse-icon.svg'
import LoadingBar from '../../../loading_bar'
import ReportDetailIndexItem from './reportDetailIndexItem'

const uuidv4 = require('uuid/v4')

export class ReportDetailIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
    }
  }

  componentDidMount = async () => {
    this.setState({ loading: false })
  }

  /*-----------------------------------------------------*/
  // CHECK FOR OPENING
  /*-----------------------------------------------------*/

  isOpen = nodeDef => {
    const { reportOpened } = this.props

    if (!reportOpened[nodeDef.type]) return false
    return nodeDef.id === reportOpened[nodeDef.type]
  }

  /*-----------------------------------------------------*/
  // TOGGLE OPENING
  /*-----------------------------------------------------*/

  toggleCollapse = nodeDef => {
    const { updateReportOpened, reportOpened } = this.props

    const updatedReportOpened = { ...reportOpened }

    // Close if Open
    if (this.isOpen(nodeDef)) updatedReportOpened[nodeDef.type] = ''
    // Open if CLose and xlose all the same family dropdown
    else updatedReportOpened[nodeDef.type] = nodeDef.id

    if (updatedReportOpened !== reportOpened) updateReportOpened(updatedReportOpened)
  }

  /*-----------------------------------------------------*/
  // Loop Index with collpased window
  /*-----------------------------------------------------*/

  loopIndexAccordion = nodeDef => {
    const { scoringTree, nodes } = this.props
    const { nodeDefsObj } = scoringTree

    return (
      <List key={uuidv4()} className="report-index-collapse" component="div" disablePadding>
        <ListItem
          key={uuidv4()}
          button
          onClick={() => this.toggleCollapse(nodeDef)}
          style={{ backgroundColor: 'transparent' }}
          className="report-index-collapse-title"
        >
          <ReportDetailIndexItem nodeDef={nodeDef} />
          {this.isOpen(nodeDef) ? <CollapseIcon /> : <ExpandIcon />}
        </ListItem>
        {nodeDef.children_ids.filter(nodeDefId => nodes[nodeDefId]) &&
          nodeDef.children_ids.filter(nodeDefId => nodes[nodeDefId]).length > 0 && (
            <Collapse in={this.isOpen(nodeDef)} timeout="auto" unmountOnExit className="report-index-collapse-list">
              {nodeDef.children_ids
                .filter(nodeDefId => nodes[nodeDefId])
                .map(nodeDefId => {
                  const childNode = nodeDefsObj[nodeDefId]

                  // loop accordion for famoly
                  if (childNode.type === 'family') return this.loopIndexAccordion(childNode)

                  // loop simple item for subfamily
                  if (childNode.type === 'subfamily')
                    return (
                      <ListItem key={uuidv4()} button className="report-index-collapse-text ">
                        <ReportDetailIndexItem nodeDef={childNode} />
                      </ListItem>
                    )

                  return ''
                })}
            </Collapse>
          )}
      </List>
    )
  }

  /*-----------------------------------------------------*/
  // Render Index
  /*-----------------------------------------------------*/

  render() {
    const { loading } = this.state
    const { scoringTree, nodes } = this.props
    const { nodeDefsObj } = scoringTree

    return (
      <>
        {loading === true && (
          <div>
            <LoadingBar />
          </div>
        )}
        {loading === false && (
          <div className="report-detail-index-list">
            {nodes &&
              Object.keys(nodes).map(nodeDefId => {
                const nodeDef = nodeDefsObj[nodeDefId]
                if (nodeDef && nodeDef.type === 'perimeter') return this.loopIndexAccordion(nodeDef)
                return ''
              })}
          </div>
        )}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    scoringTree: state.scoringTree,
    reportOpened: state.report.reportOpened,
    nodes: state.report.reportObj.nodes,
  }
}

export default connect(mapStateToProps, actionCreators)(ReportDetailIndex)
