import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../store/actions'

export class ReportField extends React.Component {
  /*-----------------------------------------------------*/
  // Update Text Data
  /*-----------------------------------------------------*/

  updateFieldData = value => {
    const { id, reportTexts, reportId, updateReportTexts } = this.props

    if (id) updateReportTexts(value, id, reportTexts, reportId)
  }

  /*-----------------------------------------------------*/
  // Render Text Field
  /*-----------------------------------------------------*/

  render() {
    const { id, label, multiline, secondaryLabel, height, reportTexts, maxChar, texts } = this.props

    // Default text
    const placeholder = maxChar ? texts.report_charachter_limit.replace('%N', maxChar) : ''
    const defaultValue = id && reportTexts[id] ? reportTexts[id] : ''

    return (
      <div className="report-field">
        {!secondaryLabel && <div className="report-label">{label}</div>}
        {secondaryLabel && <div className="report-label report-label-secondary">{label}</div>}
        {!multiline && (
          <input
            className="report-input"
            type="text"
            onChange={event => this.updateFieldData(event.target.value)}
            id={id}
            name={id}
            defaultValue={defaultValue}
          />
        )}
        {multiline && (
          <textarea
            className="report-input"
            height={height}
            id={id}
            name={id}
            onChange={event => this.updateFieldData(event.target.value)}
            defaultValue={defaultValue}
            maxLength={maxChar}
            placeholder={placeholder}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    reportTexts: state.report.reportObj.texts,
    reportId: state.report.reportObj.id,
  }
}

export default connect(mapStateToProps, actionCreators)(ReportField)
