import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../store/actions'
import { getMediaBlobUrl } from '../../../../../va-corejs-v3/api'

export class ReportPicture extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pictureUrl: null,
    }
  }

  setPictureUrl = async () => {
    const { scoring, picture } = this.props
    if (scoring[picture] && Object.keys(scoring[picture]).length > 0) {
      const pictureUrl = scoring[picture].file_thumb
      this.setState({
        pictureUrl: !pictureUrl.startsWith('data:') ? await getMediaBlobUrl(pictureUrl) : pictureUrl,
      })
    }
  }

  componentDidMount = async () => {
    await this.setPictureUrl()
  }

  componentDidUpdate = async prevProps => {
    const { scoring, picture } = this.props

    if (prevProps.scoring !== scoring || prevProps.picture !== picture) {
      await this.setPictureUrl()
    }
  }

  render() {
    const { picture } = this.props
    const { pictureUrl } = this.state

    return <div className="report-picture">{pictureUrl && <img src={pictureUrl} alt={picture} />}</div>
  }
}

const mapStateToProps = state => {
  return {
    scoring: state.scoring,
    status: state.status,
  }
}

export default connect(mapStateToProps, actionCreators)(ReportPicture)
