import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../../store/actions'
import nodeDefinitionTypeMap from '../../../../scoring_tree/helper/nodeDefinitionTypeMap'
import reportConf from '../../../helper/reportConf'
import ReportBreadcrumb from '../../commons/reportBreadcrumb'
import ReportTopLowestSelect from '../../commons/reportTopLowestSelect'

export class ReportTopLowestListItem extends React.Component {
  render() {
    const { itemId, environment, scoringTree, section, rankingType, reportMessages, texts } = this.props

    const item = scoringTree.nodeDefsObj[itemId]
    const itemScore = `${Math.round(scoringTree.scoring.nodes[itemId].percentage)}%`

    const itemName = item.name[environment.lang] ? item.name[environment.lang] : item.name[environment.defaultLang]

    // Top Lowest exceeded limit selection error
    const errorExceeded =
      reportMessages &&
      reportMessages.top_lowest &&
      reportMessages.top_lowest.exceeded &&
      reportMessages.top_lowest.exceeded === itemId

    const errorClass = errorExceeded ? 'item-error' : ''

    return (
      <>
        <div className={`report-score-item ${errorClass}`}>
          <div className="report-score-item-title flex">
            <ReportTopLowestSelect itemId={itemId} section={section} rankingType={rankingType} />

            <div className="report-score-item-name">
              <div className="report-score-item-breadcrumb">
                <ReportBreadcrumb
                  node={item}
                  structure={[nodeDefinitionTypeMap.perimeter, nodeDefinitionTypeMap.subfamily]}
                  shortName={[nodeDefinitionTypeMap.perimeter]}
                />
              </div>
              <div className="report-score-item-name-text">{itemName}</div>
            </div>

            {itemScore && <div className="report-score-item-value">{itemScore}</div>}
          </div>
        </div>
        {errorExceeded && (
          <div className="report-error-message">
            {texts.report_top_lowest_exceeded.replace('%N', reportConf.topLowestNum)}
          </div>
        )}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    environment: state.environment,
    scoringTree: state.scoringTree,
    media: state.report.media,
    texts: state.texts.values,
    reportMessages: state.report.reportMessages,
  }
}

export default connect(mapStateToProps, actionCreators)(ReportTopLowestListItem)
