import React from 'react'
import { connect } from 'react-redux'

import Layout from 'layout'
import ReportComponent from '../components/report'
import * as actionCreators from '../store/actions'

/*-----------------------------------------------------*/
// Render Report
/*-----------------------------------------------------*/

export class ReportPage extends React.Component {
  render() {
    const { location } = this.props

    return (
      <Layout>
        <ReportComponent location={location} />
      </Layout>
    )
  }
}

const mapStateToProps = state => {
  return {
    templates: state.templates,
    scoring: state.scoring,
  }
}

export default connect(mapStateToProps, actionCreators)(ReportPage)
