import { Backdrop, Button, Fade, Modal } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { publishAutoReport } from '../../../../../va-corejs-v3/actions/report'
import * as actionCreators from '../../../../store/actions'
import reportConf from '../../helper/reportConf'
import { isFamilyScored } from '../utils'

export class ReportPublish extends React.Component {
  state = {
    open: false,
    loading: false,
    success: false,
    error: false,
  }

  popupOpen = () => {
    this.setState({ open: true })
    this.reset()
  }

  popupClose = () => {
    this.setState({ open: false })
  }

  publishReportClick = async () => {
    const { report, generateReportAnalytics, updateReportType } = this.props

    this.setState({ loading: true })

    try {
      // Update the report type (eg: summary or detailed)
      await updateReportType(report.reportObj.id)

      // Generate analytics data for report before publishing
      await generateReportAnalytics(report.reportObj.id)

      const result = await publishAutoReport(report.reportObj.id)

      if (result && result.status === 200) {
        // Report Published
        this.setState({
          success: true,
          error: false,
          loading: false,
        })
      } else {
        // Publishing error
        this.setState({
          success: false,
          error: true,
          loading: false,
        })
      }
    } catch (error) {
      console.log(error)

      // Error
      this.setState({
        success: false,
        error: true,
        loading: false,
      })
    }
  }

  isTopLowestSet = () => {
    const { report, scoringTree } = this.props
    const { reportObj } = report

    const sectionsTopLowestEnabled = reportConf.reportSummaryIndex
      .filter(section => section.top_lowest)
      .map(section => section.slug)

    const topLowestSet = Object.keys(reportObj.top_lowest).every(topLowestSection => {
      return (
        !isFamilyScored(scoringTree, topLowestSection) ||
        !sectionsTopLowestEnabled.includes(topLowestSection) ||
        reportObj.top_lowest[topLowestSection].saved
      )
    })

    return topLowestSet
  }

  reset = () => {
    this.setState({
      success: false,
      error: false,
      loading: false,
    })
  }

  render() {
    const { texts, variant, report } = this.props
    const { open, loading, error, success } = this.state
    const textVariant = `${variant} ${texts.report}`
    const reportTitle = report.reportObj.texts.report_title || texts.report_untitled
    const benchmarkName = report.reportObj.texts.benchmark_name

    const isDone = () => {
      return !loading && (success || error)
    }

    const isSuccess = () => {
      return !loading && !error && success
    }

    const isError = () => {
      return !loading && error && !success
    }

    return (
      <>
        <Button
          variant="contained"
          color="primary"
          onClick={() => this.popupOpen()}
          className="report-popup-publish-open"
        >
          {texts.report_publish}
        </Button>

        <Modal
          className="report-popup-publish"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={() => this.popupClose()}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className="report-popup report-popup-publish-window">
              <div className="report-popup-publish-content">
                <div className="report-popup-publish-headline">
                  {`${texts.report_publishing} ${textVariant}`}
                  {!this.isTopLowestSet() && !loading && !isDone() && (
                    <div className="report-error-message">{texts.report_top_lowest_not_set}</div>
                  )}
                </div>

                <div className="report-popup-description">
                  {!loading && !isDone() && (
                    <>
                      <div className="report-popup-publish-intro">{texts.report_about_publish}</div>

                      <div className="report-popup-publish-title">{reportTitle.replace('%REPORT', textVariant)}</div>

                      <div className="report-popup-publish-benchmark">
                        {benchmarkName && `${texts.benchmark}: ${benchmarkName}`}
                      </div>
                    </>
                  )}

                  {loading && !isDone() && (
                    <>
                      <div className="report-popup-publish-icon">
                        <CircularProgress size={80} />
                      </div>
                      <div className="report-popup-publish-intro">{texts.report_sending_text}</div>
                    </>
                  )}

                  {isSuccess() && (
                    <>
                      <div className="report-popup-publish-icon">
                        <CheckCircleOutlineIcon color="disabled" fontSize="inherit" />
                      </div>
                      <div className="report-popup-publish-intro">{texts.report_publish_success}</div>
                    </>
                  )}

                  {isError() && (
                    <>
                      <div className="report-popup-publish-icon report-popup-publish-icon--error">
                        <ErrorOutlineIcon color="inherit" fontSize="inherit" />
                      </div>
                      <div className="report-popup-publish-intro">{texts.report_publish_error}</div>
                    </>
                  )}
                </div>
              </div>

              <div className="report-popup-actions flex">
                {!isDone() && (
                  <>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() => this.popupClose()}
                      className="report-popup-publish-cancel"
                      disabled={loading}
                    >
                      {texts.cancel}
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => this.publishReportClick()}
                      className="report-popup-publish-button"
                      disabled={loading}
                    >
                      {texts.publish}
                    </Button>
                  </>
                )}

                {isDone() && (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => this.popupClose()}
                    className="report-popup-publish-button"
                  >
                    {texts.ok}
                  </Button>
                )}
              </div>
            </div>
          </Fade>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    authentication: state.authentication,
    texts: state.texts.values,
    media: state.report.media,
    scoringTree: state.scoringTree,
    report: state.report,
  }
}

export default connect(mapStateToProps, actionCreators)(ReportPublish)
