import React from 'react'
import { connect } from 'react-redux'
// import { Button } from '@material-ui/core'
import * as actionCreators from '../../../../store/actions'
// import ReportField from '../commons/reportField'
// import AddMedia from '../../../../assets/svg/add-media.svg'
import ReportPopupMedia from '../commons/reportPopupMedia'

export class ReportDetailScoreEvaluation extends React.Component {
  /*-----------------------------------------------------*/
  // Update Family Evaluation Data
  /*-----------------------------------------------------*/

  updateFieldData = value => {
    const { reportObj, updateReportScoreNodes, evaluationNodeDef } = this.props
    const { nodes } = reportObj

    if (nodes[evaluationNodeDef.id]) {
      const updatedNodes = { ...nodes }
      updatedNodes[evaluationNodeDef.id].text = value
      updateReportScoreNodes(updatedNodes, reportObj.id)
    }
  }

  /*-----------------------------------------------------*/
  // Render Evaluation
  /*-----------------------------------------------------*/

  render() {
    const { reportObj, texts, evaluationNodeDef } = this.props
    const currDetailNode = reportObj.nodes[evaluationNodeDef.id]
    const defaultValue = currDetailNode.text != null ? currDetailNode.text : ''

    return (
      <div className="report-detail-score-evaluation report-spacing flex">
        <div className="report-field">
          <div className="report-label label-l">{texts.report_synthesis}</div>

          <textarea
            className="report-input"
            onChange={event => this.updateFieldData(event.target.value)}
            value={defaultValue}
          />
        </div>

        <ReportPopupMedia popupNode={evaluationNodeDef} maxNumSelect={3} />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    reportObj: state.report.reportObj,
    texts: state.texts.values,
    scoringTree: state.scoringTree,
  }
}
export default connect(mapStateToProps, actionCreators)(ReportDetailScoreEvaluation)
