import React from 'react'
import { connect } from 'react-redux'

import { Button } from '@material-ui/core'
import * as actionCreators from '../../../../store/actions'
import HideSideBarIcon from '../../../../assets/svg/hide-sidebar.svg'
import ShowSideBarIcon from '../../../../assets/svg/show-sidebar.svg'

export class ReportDetailScoreHeader extends React.Component {
  toggleSidebar = () => {
    const { updateReportSidebarVisibility, reportSidebarVisible } = this.props
    updateReportSidebarVisibility(!reportSidebarVisible)
  }

  render() {
    const { reportDetailSection, reportSidebarVisible, environment, texts } = this.props

    return (
      <div className="report-detail-title flex">
        <div className="toggle-sidebar-btn">
          {reportSidebarVisible ? (
            <Button
              variant="contained"
              color="primary"
              startIcon={<HideSideBarIcon />}
              onClick={() => this.toggleSidebar()}
              className="round-btn sidebar-off icon-btn"
            />
          ) : (
            <Button
              variant="contained"
              color="secondary"
              startIcon={<ShowSideBarIcon />}
              onClick={() => this.toggleSidebar()}
              className="round-btn sidebar-on"
            >
              {texts.show_sidebar}
            </Button>
          )}
        </div>
        <div className="report-detail-title-text">
          {reportDetailSection &&
            reportDetailSection.name &&
            (reportDetailSection.name[environment.lang]
              ? reportDetailSection.name[environment.lang]
              : reportDetailSection.name[environment.defaultLang])}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    reportDetailSection: state.report.reportDetailSection,
    texts: state.texts.values,
    reportSidebarVisible: state.report.reportSidebarVisible,
    environment: state.environment,
  }
}

export default connect(mapStateToProps, actionCreators)(ReportDetailScoreHeader)
