import React from 'react'
import { connect } from 'react-redux'
import ReportDetailScoreHeader from './reportDetailScoreHeader'
import * as actionCreators from '../../../../store/actions'
import ReportDetailScoreEvaluation from './reportDetailScoreEvaluation'
import ReportDetailScoreList from './reportDetailScoreList'

export class ReportDetailScore extends React.Component {
  /*-----------------------------------------------------*/
  // Render Items
  /*-----------------------------------------------------*/

  render() {
    const { reportDetailSection, filtersList, filtersGlobal } = this.props
    let bestLimit =
      filtersList && filtersList[reportDetailSection.id] && filtersList[reportDetailSection.id].best
        ? filtersList[reportDetailSection.id].best
        : 50
    let worstLimit =
      filtersList && filtersList[reportDetailSection.id] && filtersList[reportDetailSection.id].worst
        ? filtersList[reportDetailSection.id].worst
        : 50

    bestLimit = filtersGlobal.best || bestLimit
    worstLimit = filtersGlobal.worst === undefined || filtersGlobal.worst === '' ? worstLimit : filtersGlobal.worst
    if (worstLimit === 0) worstLimit = 1 // in order to select all 0%

    return (
      <div className="report-detail-score">
        <ReportDetailScoreHeader />

        {reportDetailSection.type === 'subfamily' && (
          <ReportDetailScoreEvaluation evaluationNodeDef={reportDetailSection} />
        )}

        <div className="report-score-list-columns flex">
          <ReportDetailScoreList section={reportDetailSection} variant="best" limit={bestLimit} />
          <ReportDetailScoreList section={reportDetailSection} variant="worst" limit={worstLimit} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    reportDetailSection: state.report.reportDetailSection,
    texts: state.texts.values,
    filtersList: state.report.reportFiltersList,
    filtersGlobal: state.report.reportFiltersGlobal,
  }
}

export default connect(mapStateToProps, actionCreators)(ReportDetailScore)
