import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import * as actionCreators from '../../../../store/actions'
import ReportMediaListSelector from './reportMediaListSelector'
import ReportMediaListUnselect from './reportMediaListUnselect'
import { getMediaBlobUrl } from '../../../../../va-corejs-v3/api'

const uuidv4 = require('uuid/v4')

export class ReportMediaList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mediaList: [],
    }
  }

  componentDidMount = async () => {
    await this.setMediaList()
  }

  componentDidUpdate = async prevProps => {
    const { media, mediaNodeDef } = this.props

    if (prevProps.mediaNodeDef !== mediaNodeDef || prevProps.media !== media) {
      await this.setMediaList()
    }
  }

  setMediaList = async () => {
    const { media, mediaNodeDef, scoringTree } = this.props
    const { nodeDefsObj: nodeDefinitions } = scoringTree
    let mediaList = []

    // Media for items
    if (mediaNodeDef.type === 'item') {
      mediaList = media.filter(
        currMedia =>
          currMedia.node_definition_id === mediaNodeDef.id ||
          mediaNodeDef.children_ids.includes(currMedia.node_definition_id)
      )
      mediaList.sort((a, b) => {
        if (a.order < b.order) return -1
        if (a.order > b.order) return 1
        return 0
      })
    } else if (mediaNodeDef.children_ids) {
      // TO DO: to filter with only children enabled
      const filteredNodeDefinitions = _.filter(nodeDefinitions, _nodeDefinition => {
        const { id } = _nodeDefinition
        return (
          mediaNodeDef._left < _nodeDefinition._left &&
          _nodeDefinition._right < mediaNodeDef._right &&
          scoringTree.scoring.nodes[id] &&
          scoringTree.scoring.nodes[id].is_enabled
        )
      })

      _.forEach(filteredNodeDefinitions, _nodeDefinition => {
        const { id: childId } = _nodeDefinition
        const childMediaList = media.filter(currMedia => currMedia.node_definition_id === childId)
        childMediaList.sort((a, b) => {
          if (a.order < b.order) return -1
          if (a.order > b.order) return 1
          return 0
        })
        mediaList = [...mediaList, ...childMediaList]
      })
    }

    // Get blob urls of the media
    const promises = []
    _.each(mediaList, item => {
      if (item.file_thumb) {
        promises.push(
          new Promise((resolve, reject) => {
            if (!item.file_thumb.startsWith('data:')) {
              getMediaBlobUrl(item.file_thumb)
                .then(response => {
                  resolve({
                    id: item.id,
                    thumbUrl: response,
                  })
                })
                .catch(error => {
                  reject(error)
                })
            } else {
              resolve({
                id: item.id,
                thumbUrl: item.file_thumb,
              })
            }
          })
        )
      }
    })

    const results = await Promise.all(promises)
    mediaList = _.map(mediaList, item => {
      const result = _.find(results, r => r.id === item.id)
      item.thumbBlobUrl = result ? result.thumbUrl : null
      return item
    })
    // End get blob urls

    this.setState({
      mediaList,
    })
  }

  render() {
    const { mediaNodeDef, maxNumSelect } = this.props
    const { mediaList } = this.state

    return (
      <div key={uuidv4()} className="report-media-list">
        <ReportMediaListUnselect mediaNodeDef={mediaNodeDef} maxNumSelect={maxNumSelect} />
        <div key={uuidv4()} className="report-media-list-inner">
          {mediaList.length > 0 &&
            mediaList.map(itemMedia => (
              <div key={uuidv4()} className="report-media-list-item">
                <ReportMediaListSelector media={itemMedia} mediaNodeDef={mediaNodeDef} maxNumSelect={maxNumSelect} />
                {itemMedia.thumbBlobUrl && <img src={itemMedia.thumbBlobUrl} alt="report gallery media" />}
              </div>
            ))}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    media: state.report.media,
    scoringTree: state.scoringTree,
  }
}

export default connect(mapStateToProps, actionCreators)(ReportMediaList)
