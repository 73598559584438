import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../store/actions'
import ReportPublish from './reportPublish'
import ReportConfidentialSwitch from './reportConfidentialSwitch'

export class ReportActions extends React.Component {
  render() {
    const { variant, authentication } = this.props

    return (
      <div className="report-actions flex report-spacing">
        <ReportConfidentialSwitch />
        {authentication.user.parsedPermissions.canPublishReport && <ReportPublish variant={variant} />}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    authentication: state.authentication,
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, actionCreators)(ReportActions)
