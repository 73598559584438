import { ListItem } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../store/actions'
import ReportCheckbox from '../commons/reportCheckbox'

export class ReportDetailIndexItem extends React.Component {
  /*-----------------------------------------------------*/
  // Select Score Node To show in detail content
  /*-----------------------------------------------------*/

  itemClick = () => {
    const { updateReportDetailSection, nodeDef } = this.props
    updateReportDetailSection(nodeDef)
  }

  /*-----------------------------------------------------*/
  // Render Index Detail Item
  /*-----------------------------------------------------*/

  render() {
    const { nodeDef, environment, reportDetailSection } = this.props

    return (
      <ListItem
        button
        onClick={() => this.itemClick()}
        selected={nodeDef === reportDetailSection}
        className="report-index-item flex"
        style={{ backgroundColor: 'transparent' }}
      >
        <ReportCheckbox itemId={nodeDef.id} />

        <div className="report-index-item-text">
          {nodeDef.name[environment.lang] ? nodeDef.name[environment.lang] : nodeDef.name[environment.defaultLang]}
        </div>
      </ListItem>
    )
  }
}

const mapStateToProps = state => {
  return {
    environment: state.environment,
    config: state.environment.config,
    scoringTree: state.scoringTree,
    reportDetailSection: state.report.reportDetailSection,
  }
}

export default connect(mapStateToProps, actionCreators)(ReportDetailIndexItem)
