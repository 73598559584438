import { Button } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../store/actions'
import ResetIcon from '../../../../assets/svg/reset-icon.svg'

export class ReportMediaListnselect extends React.Component {
  unselectAll = () => {
    const { reportObj, mediaNodeDef, updateReportScoreNodes } = this.props
    const { nodes } = reportObj
    nodes[mediaNodeDef.id].media_selected_ids = []
    updateReportScoreNodes(nodes, reportObj.id)
    return false
  }

  render() {
    const { texts, maxNumSelect } = this.props
    const textMaxSelection = texts.report_image_max_selection.replace('%N', maxNumSelect)

    return (
      <div className="report-media-reset flex">
        <div className="report-media-reset-text ">
          <span className="reset-selection">{texts.report_image_selection}</span>
          <span className="reset-max-text">{textMaxSelection}</span>
        </div>

        <Button
          variant="contained"
          color="secondary"
          startIcon={<ResetIcon />}
          onClick={() => this.unselectAll()}
          className="round-btn"
        >
          {texts.report_unselect}
        </Button>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    reportObj: state.report.reportObj,
  }
}

export default connect(mapStateToProps, actionCreators)(ReportMediaListnselect)
