import React from 'react'
import reportPageMap from '../../helper/reportPageMap'
import ReportSummaryContent from '../summary/reportSummaryContent'
import ReportSummaryHeader from '../summary/reportSummaryHeader'
import ReportSummaryNavbar from '../summary/reportSummaryNavbar'
import ReportBrand from './reportBrand'
import ReportDetailHeader from '../detail/reportDetailHeader'
import ReportDetailNavbar from '../detail/reportDetailNavbar'
import ReportSummaryOnlyNavbar from '../summary/reportSummaryOnlyNavbar'
import ReportDetailContent from '../detail/reportDetailContent'

export class ReportContent extends React.Component {
  render() {
    const { page } = this.props
    const pageName = Object.keys(reportPageMap)[page]
    const classPage = `report-${pageName} report-page`

    return (
      <div className={classPage}>
        <div className="report-header flex">
          <ReportBrand />
          {(page === reportPageMap.summary || page === reportPageMap.summaryOnly) && <ReportSummaryHeader />}
          {page === reportPageMap.detail && <ReportDetailHeader />}
        </div>

        <div className="report-content">
          {(page === reportPageMap.summary || page === reportPageMap.summaryOnly) && <ReportSummaryContent />}
          {page === reportPageMap.detail && <ReportDetailContent />}
        </div>

        <nav className="report-navbar flex report-spacing">
          {page === reportPageMap.summaryOnly && <ReportSummaryOnlyNavbar />}
          {page === reportPageMap.summary && <ReportSummaryNavbar />}
          {page === reportPageMap.detail && <ReportDetailNavbar />}
        </nav>
      </div>
    )
  }
}

export default ReportContent
