import { Select } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux'
import ExpandMoreSharpIcon from '@material-ui/icons/ExpandMoreSharp'
import * as actionCreators from '../../../../store/actions'
import reportRankingTypeMap from '../../helper/reportRankingTypeMap'

const uuidv4 = require('uuid/v4')

export class ReportFilterList extends React.Component {
  /*-----------------------------------------------------*/
  // Select Filter
  /*-----------------------------------------------------*/

  updateFilter = value => {
    const {
      sectionId,
      filters,
      filtersGlobal,
      variant,
      updateReportFiltersList,
      updateReportFiltersGlobal,
    } = this.props

    if (!filters[sectionId] || !filters[sectionId][variant] || filters[sectionId][variant] !== value) {
      const updatedfilters = { ...filters }
      const currFilter = {}
      currFilter[variant] = value

      if (typeof value === 'string' && value.split(' ').length < 2) currFilter[variant] = parseInt(value, 10)

      updatedfilters[sectionId] = {
        ...updatedfilters[sectionId],
        ...currFilter,
      }
      updateReportFiltersList(updatedfilters)

      if (filtersGlobal[variant]) {
        const updatedfiltersGlobal = { ...filtersGlobal }
        updatedfiltersGlobal[variant] = ''
        updateReportFiltersGlobal(updatedfiltersGlobal)
      }
    }
  }

  /*-----------------------------------------------------*/
  // Get Values
  /*-----------------------------------------------------*/

  isValue = value => {
    const { sectionId, filters, variant } = this.props
    return filters[sectionId] && filters[sectionId][variant] && filters[sectionId][variant] === value
  }

  getValue = valuesList => {
    if (!valuesList) return ''
    const updateValuesList = [...valuesList]
    const currValue = updateValuesList.pop()

    if (!currValue) return ''

    if (this.isValue(currValue)) return currValue
    return this.getValue(updateValuesList)
  }

  /*-----------------------------------------------------*/
  // Render Global Select
  /*-----------------------------------------------------*/

  render() {
    const { values, variant, texts } = this.props
    const list = values && Array.isArray(values) ? values : ''

    const menuItemsValues = list ? ['', ...list] : ['']

    const prefix = variant === reportRankingTypeMap.best ? `${texts.all} >` : `${texts.all} <`

    return (
      <div className="report-filter-list report-select">
        <span className="report-select-label">{texts.filter}</span>
        <Select
          value={this.getValue(list)}
          onChange={event => this.updateFilter(event.target.value)}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          IconComponent={ExpandMoreSharpIcon}
          native
        >
          {menuItemsValues.map(menuItemsValue => {
            let selectText = ''

            if (!menuItemsValue) selectText = texts.all
            else if (Number.isInteger(menuItemsValue)) selectText = `${prefix} ${menuItemsValue}%`
            else selectText = menuItemsValue
            return (
              <option key={uuidv4()} value={menuItemsValue}>
                {selectText}
              </option>
            )
          })}
        </Select>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    reportId: state.report.reportObj.id,
    nodes: state.report.reportObj.nodes,
    scoringTree: state.scoringTree,
    texts: state.texts.values,
    filters: state.report.reportFiltersList,
    filtersGlobal: state.report.reportFiltersGlobal,
  }
}

export default connect(mapStateToProps, actionCreators)(ReportFilterList)
