import React from 'react'
import { connect } from 'react-redux'

import _ from 'lodash'
import * as actionCreators from '../../../../store/actions'
import mediaCollectionsMap from '../../../../../va-corejs-v3/utils/mediaCollectionsMap'
import scopePropsMap from '../../../scoring/_parts/helper/scopePropsMap'
import ContextualMenu from '../../../scoring_tree/_parts/contextualMenu'
import { getMediaBlobUrl } from '../../../../../va-corejs-v3/api'

export class ReportBrand extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      featuredImgThumbUrl: null,
      brandLogoUrl: null,
    }
  }

  setFeaturedImgThumbUrl = async () => {
    const { scoringTree } = this.props
    const featuredImgThumb = scoringTree.header.featuredImg.file_thumb
    this.setState({
      featuredImgThumbUrl: featuredImgThumb ? await getMediaBlobUrl(featuredImgThumb) : null,
    })
  }

  setBrandLogoUrl = async () => {
    const { scoringTree } = this.props
    const brand = scoringTree.scoring.props.filter(x => x.slug === scopePropsMap.brand)[0]
    const brandDef = scoringTree.scopeProps.filter(x => x.slug === scopePropsMap.brand)[0]
    if (brand.slug) {
      const brandLogoObject = brandDef.values.filter(x => x.slug === brand.value.slug)[0]
      const brandLogo =
        brandLogoObject && brandLogoObject.media && brandLogoObject.media.length > 0 ? brandLogoObject.media[0].url : ''

      this.setState({
        brandLogoUrl: brandLogo ? await getMediaBlobUrl(brandLogo) : null,
      })
    }
  }

  componentDidMount = async () => {
    const { getScoringTreeFeaturedImage, scoringTree } = this.props
    await getScoringTreeFeaturedImage(scoringTree.scoring.id, mediaCollectionsMap.cover_pictures)

    await this.setFeaturedImgThumbUrl()
    await this.setBrandLogoUrl()
  }

  render() {
    const { environment, scoringTree } = this.props
    const { featuredImgThumbUrl, brandLogoUrl } = this.state

    // model
    const model = scoringTree.scoring.props.filter(x => x.slug === scopePropsMap.model)[0].value.body[environment.lang]

    // brandName
    const brand = scoringTree.scoring.props.filter(x => x.slug === scopePropsMap.brand)[0]

    const brandName = brand.value.body[environment.defaultLang]

    // commercialNameGrade
    let commercialNameGrade = _.find(scoringTree.scoring.props, prop => {
      return prop.slug === scopePropsMap.comm_name_grade
    })

    commercialNameGrade = commercialNameGrade ? commercialNameGrade.value.body[environment.defaultLang] : ''

    /*-----------------------------------------------------*/
    // Render
    /*-----------------------------------------------------*/

    return (
      <div className="report-brand flex">
        <div className="report-brand-featured">
          <img src={featuredImgThumbUrl} alt="featured" />
          <ContextualMenu showEditScoring />
        </div>
        <div className="report-brand-content flex report-spacing">
          <div className="report-brand-logo">
            {brandLogoUrl && <img src={brandLogoUrl} alt="brand logo" />}
            {!brandLogoUrl && (
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                <circle
                  cx="609"
                  cy="222"
                  r="14.5"
                  fill="#D8D8D8"
                  fillRule="evenodd"
                  stroke="#000"
                  strokeOpacity=".1"
                  transform="translate(-594 -207)"
                />
              </svg>
            )}
          </div>
          <div className="report-brand-name">
            <div className="report-brand-name-title title-l">{`${brandName} ${model}`}</div>
            <div className="report-brand-name-subtitle title-xs">{commercialNameGrade}</div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    environment: state.environment,
    scoring: state.scoring,
    scoringTree: state.scoringTree,
    sync: state.sync,
  }
}

export default connect(mapStateToProps, actionCreators)(ReportBrand)
