import { List, ListItem } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import * as actionCreators from '../../../../../store/actions'
import { getItemsRankingInFamily } from '../../utils'
import ReportTopLowestListItem from './reportTopLowestListItem'
import reportConf from '../../../helper/reportConf'
import ReportOrderList from '../../commons/reportOrderList'
import reportRankingTypeMap from '../../../helper/reportRankingTypeMap'
import reportOrderMap from '../../../helper/reportOrderMap'

export class ReportTopLowestList extends React.Component {
  /*-----------------------------------------------------*/
  // Calculate List items greater or less than limit value
  /*-----------------------------------------------------*/

  sortByWeight = list => {
    if (!list) return false

    const { scoringTree, rankingType } = this.props

    let sortedList = [...list]

    sortedList = sortedList.sort((a, b) => {
      const aPerc = scoringTree.scoring.nodes[a.id].percentage || 0
      const aWeight = scoringTree.nodeDefsObj[a.id].weight || 1
      const bPerc = scoringTree.scoring.nodes[b.id].percentage || 0
      const bWeight = scoringTree.nodeDefsObj[b.id].weight || 1

      const valueA = aPerc * aWeight
      const valueB = bPerc * bWeight

      if (valueA > valueB) return rankingType === reportRankingTypeMap.best ? -1 : 1
      if (valueA < valueB) return rankingType === reportRankingTypeMap.best ? 1 : -1

      return 0
    })

    return sortedList
  }

  /*-----------------------------------------------------*/
  // List rendering
  /*-----------------------------------------------------*/

  render() {
    const { scoringTree, rankingType, section, texts, order, environment } = this.props
    const { nodeDefsObj: nodeDefinitions } = scoringTree
    const { lang: currentLocale } = environment

    const defaultLimit = reportConf && reportConf.topLowestColumnLimit ? reportConf.topLowestColumnLimit : 50

    // perimeters list
    const perimeters = _.filter(nodeDefinitions, nodeDefinition => {
      return nodeDefinition.parent_id === null
    })

    // default ranked list
    const items = _.map(getItemsRankingInFamily(scoringTree, section, defaultLimit, rankingType), id => {
      return nodeDefinitions[id]
    })

    return (
      <div className={`report-score-list ${rankingType}`}>
        <div className="report-score-list-header flex">
          <div className="report-score-title">
            {rankingType === reportRankingTypeMap.best ? texts.report_best : texts.report_worst}
            <span className="report-score-title-info">
              {texts.report_select_n_items.replace('%N', reportConf.topLowestNum)}
            </span>
          </div>

          <div className="report-score-actions flex">
            <ReportOrderList
              collection="top_lowest"
              section={section}
              rankingType={rankingType}
              values={[reportOrderMap.performance, reportOrderMap.weighted]}
            />
          </div>
        </div>

        <List className="report-score-list-inner">
          {perimeters.map(perimeter => {
            let filteredItems = _.filter(items, _item => {
              // These are the children of the current perimeter
              return _item._left > perimeter._left && _item._right < perimeter._right
            })

            // select sorting by weight re-order list
            if (
              order &&
              order.top_lowest &&
              order.top_lowest[section] &&
              order.top_lowest[section][rankingType] === reportOrderMap.weighted
            ) {
              filteredItems = this.sortByWeight(filteredItems)
            }

            return (
              <>
                {filteredItems && filteredItems.length > 0 && (
                  <>
                    <div className="report-score-item report-score-item-header">{perimeter.name[currentLocale]}</div>
                    {filteredItems.map(item => {
                      return (
                        <ListItem key={item.id}>
                          <ReportTopLowestListItem itemId={item.id} section={section} rankingType={rankingType} />
                        </ListItem>
                      )
                    })}
                  </>
                )}
              </>
            )
          })}
        </List>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    scoringTree: state.scoringTree,
    nodes: state.report.reportObj.nodes,
    texts: state.texts.values,
    order: state.report.reportOrder,
    environment: state.environment,
  }
}

export default connect(mapStateToProps, actionCreators)(ReportTopLowestList)
