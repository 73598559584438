import { Switch } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../store/actions'

export class ReportConfidentialSwitch extends React.Component {
  state = {
    checked: false,
  }

  componentDidMount = () => {
    const { confidential } = this.props
    this.setState({
      checked: confidential,
    })
  }

  handleChange = event => {
    const { updateReportConfidential, reportId } = this.props

    this.setState({
      checked: event.target.checked,
    })

    updateReportConfidential(event.target.checked, reportId)
  }

  render() {
    const { texts } = this.props
    const { checked } = this.state

    return (
      <div className="report-confidential">
        <span className="report-confidential-label">{texts.report_confidential}</span>
        <Switch
          disableRipple
          className="report-switch"
          color="primary"
          checked={checked}
          onChange={this.handleChange}
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    confidential: state.report.reportObj.confidential,
    reportId: state.report.reportObj.id,
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, actionCreators)(ReportConfidentialSwitch)
